import Contact from "./components/Contact";
import Explanation from "./components/Explanation";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Team from "./components/Team";
import Validation from "./components/Validation";
import ObjectionBreak from "./components/objectionBreak";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..

import '../src/styles.css'


function App() {

  AOS.init({
    once: true,
    duration: 1000,
    easing: 'ease',
  });


  return (
  <>
  <Header />
  <Hero />
  <Validation />
  <Explanation />
  <Team />
  <ObjectionBreak />
  <Contact />
  <Footer />
  </>
  );
}

export default App;
