import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons';

import { CloseIcon } from '@chakra-ui/icons';

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  return (
    <Box py={12}
    justify="center"
    align={"center"}>
      <VStack spacing={2} textAlign="center">
      <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">

        <Heading as="h1" fontSize="4xl">
        Não sabe por onde começar?
        </Heading>
        </div>
        <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
        <Text fontSize="lg" color={'gray.500'}>
          Nós podemos ajudar a orientá-lo no processo de desenvolvimento.
        </Text>
        </div>
      </VStack>
      <Stack
        minH={'450px'}
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        align={"center"}
        spacing={{ base: 4, lg: 10 }}
        py={10}>
            <div data-aos="flip-down">
        <PriceWrapper>
        <Box minH={'450px'} pb={5}>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
            Sem MVP Plus
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="xl" color="gray.500">
            mais do mesmo
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={6} px={12}>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Dificuldade em gerenciar e armazenar informações;
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300"/>
                Falta de automação de processos;
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Dificuldade em rastrear o desempenho do negócio;
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Vulnerabilidade à segurança de dados;
              </ListItem>
              <ListItem textAlign="start" >
                <ListIcon as={CloseIcon} color="gray.300" />
                Falta de escalabilidade.
              </ListItem>
              
            </List>
            <Box w="80%" pt={7}>
                <Button w="full" colorScheme="orange" variant="outline" alignSelf={'center'} justifySelf={'center'} textAlign={'center'}>
                Quero mudar!
              </Button></Box>
          </VStack>
          </Box>
        </PriceWrapper>
        </div>
        <div data-aos="flip-down">
        <PriceWrapper >
          <Box position="relative" minH={'450px'} borderRadius={15}   bg={useColorModeValue('blue.800', 'blue.700')} pb={5}>
            <Box
            
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('orange.500', 'orange.700')}
                px={3}
                py={1}
                color={useColorModeValue('white', 'white')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Desenvolva já!
              </Text>
            </Box>
            <Box py={4} px={12} >
              <Text fontWeight="500" fontSize="2xl" color={'white'}>
              Com a MVP PLus
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="2xl" color="gray.300">
                  Supreenda!
                </Text>
              </HStack>
            </Box>
            <VStack
            
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={6} textAlign="start" px={12} color={'white'}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Maior eficiência e produtividade!
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Melhoria da tomada de decisão;
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Maior segurança de dados: Um software personalizado;
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Melhor atendimento ao cliente;
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Escalabilidade & muito mais!
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="orange">
                  Fale conosco
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        </div>
      </Stack>
    </Box>
  );
}